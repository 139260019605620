import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";

import { actionStockDelete } from "store/slices";
import { useAppDispatch, useAppSelector } from "store";
import { CreatableValidatorDialog } from "components/global/general";

import { IStockHistoryTableProps, StockHistoryTableRowContent } from "./StocksHistoryTable";
import { CreatableEditStockHistoryDrawer } from "./module/EditStockHistoryDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { IBackendUserLevel } from "interfaces";


const useModule = ({ ticker }: IStockHistoryTableProps) => {
	/** @Globals */
	const intl = useIntl()
	const dispatch = useAppDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	/** @Selectors */
	const isInternetConnected = useAppSelector(state => state.session.isWebSocketConnected)
	const stateStorageStocks = useAppSelector(state => state.storage.stockPriceHistory)
	const stateStock = useAppSelector(state => state.vault.stocks?.[ticker])
	const isPremium = useAppSelector(state => state.session.level !== IBackendUserLevel.ULRegular)
	const stateStockStatistics = useAppSelector(state => state.statistics.stocks)

	/** @States */
	const [flags, setflags] = useState({
		isTherePremiumRecords: false,
	})
	const [rows, setrows] = useState<StockHistoryTableRowContent[]>([])

	/** @Functions */
	const updateFlags = (newFlags: Partial<typeof flags>) => {
		setflags((prev) => ({
			...prev,
			...newFlags
		}))
	}

	const handleEditStockOperation = async (operationId: string) => {
		await CreatableEditStockHistoryDrawer({
			stockTicker: ticker,
			operationId
		})
		return
	}

	const handleDeleteStockOperation = async (operationId: string) => {
		await CreatableValidatorDialog({
			dialogTitle: intl.formatMessage({ defaultMessage: 'Are you sure you want to delete this operation?', id: 'stocks.components.historyTable.dialog.delete.title' }),
			dialogDescription: intl.formatMessage({ defaultMessage: 'This action is not reversible. The funds cannot be automatically reversed to the origin account.', id: 'stocks.components.historyTable.dialog.delete.description' }),
			cancelButtonLabel: intl.formatMessage({ defaultMessage: 'Cancel', id: 'stocks.components.historyTable.dialog.delete.cancel' }),
			confirmButtonLabel: intl.formatMessage({ defaultMessage: 'Delete Permanently', id: 'stocks.components.historyTable.dialog.delete.confirm' }),
		})

		dispatch(actionStockDelete(stateStock.history[operationId]))
		enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Stock operation successfully deleted', id: 'stocks.components.historyTable.dialog.delete.success' }), { variant: 'success', persist: false, preventDuplicate: false, autoHideDuration: 2000 })
	}

	const isRowDisabled = (opId: string) => {
		if (isPremium) {
			return false
		}

		return !stateStockStatistics.nonPremiumStocks?.[ticker]?.records?.[opId]
	}

	/** @Effects */
	useEffect(() => {

		const newRows = Object.values(stateStock?.history || {}).map((stock) => {

			return {
				id: stock.id,
				date: moment(stock.date).tz("Etc/GMT+6").toDate(),
				operationType: stock.operationType,
				priceAtOperation: stock.price,
				shares: stock.numberShares,
				valueAtOperation: stock.price * stock.numberShares,
			} as StockHistoryTableRowContent

		})

		setrows(newRows)

	}, [stateStock, stateStorageStocks])

	useEffect(() => {
		const areTherePremiumRecords = Object.values(stateStockStatistics.nonPremiumStocks?.[ticker]?.records || {}).some(j => !j)
		updateFlags({ isTherePremiumRecords: areTherePremiumRecords })
	}, [stateStockStatistics.nonPremiumStocks])

	/** @Constants */

	return {
		flags: {
			isInternetConnected,
			...flags
		},
		local: {
			rows,
			isMobile,
			isPremium,
		},
		methods: {
			isRowDisabled,
			handleEditStockOperation,
			handleDeleteStockOperation,
		}
	}
}

export default useModule;