import moment from 'moment-timezone';
import { useEffect, useRef, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from "store";
import { IStockHistoriesAPIInput } from 'store/actions';
import { IProcessQueueTaskNames, actionEnqueueUniqueProcess, actionStorageSyncSetLoading, actionUpdateGlobalFlags } from "store/slices";

const useStoreStockManager = (FETCH_RATE: number = 3_600_000) => {
	/** @Globals */
	const dispatch = useAppDispatch()
	const uniqueOperationsRef = useRef<boolean>(false)
	const intervalStocksRef = useRef<number | null>(null)
	const intervalDividendRef = useRef<number | null>(null)

	/** @Selectors */
	const isWSConnected = useAppSelector(state => state.session.isWebSocketConnected)
	const stocks = useAppSelector(state => state.vault.stocks)

	/** @States */
	const flags = useAppSelector(state => state.flags.global)

	/** @Cache */
	const stockWatcherInputs: { [key: string]: IStockHistoriesAPIInput } = useMemo(() => {
		const inputs = {} as { [key: string]: IStockHistoriesAPIInput }

		Object.keys(stocks).forEach((stock) => {
			const stockInformation = stocks[stock]

			inputs[stock] = {
				ticker: stock,
				fromDate: moment(stockInformation.oldestRecordDate).startOf('year').subtract(5, 'days').set('hour', 6).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
			}
		})

		return inputs
	}, [stocks])

	/** @Functions */

	/** @Effects */
	useEffect(() => {

		// If the WebSocket is connected, we will set the intervals
		if (isWSConnected) {
			// Only if the Vault has received the first sync, we will request the following data
			if (flags["internal:store:hasReceivedFirstSync"] && !uniqueOperationsRef.current && Object.keys(stockWatcherInputs).length > 0) {
				uniqueOperationsRef.current = true

				// Interval Stocks
				intervalStocksRef.current = window.setInterval(() => {
					dispatch(actionEnqueueUniqueProcess({
						processName: IProcessQueueTaskNames.REQUEST_STOCKS,
						payload: stockWatcherInputs,
					}))

				}, FETCH_RATE)

				// Interval Dividends
				intervalDividendRef.current = window.setInterval(() => {
					dispatch(actionEnqueueUniqueProcess({
						processName: IProcessQueueTaskNames.REQUEST_DIVIDENDS,
						payload: stockWatcherInputs,
					}))

				}, FETCH_RATE)

				// Set loading
				dispatch(actionStorageSyncSetLoading(Object.keys(stockWatcherInputs)))

				// Request at start
				dispatch(actionEnqueueUniqueProcess({
					processName: IProcessQueueTaskNames.REQUEST_STOCKS,
					payload: stockWatcherInputs,
				}))
				dispatch(actionEnqueueUniqueProcess({
					processName: IProcessQueueTaskNames.REQUEST_DIVIDENDS,
					payload: stockWatcherInputs,
				}))
			}

			if (flags["internal:store:hasReceivedFirstSync"] && !uniqueOperationsRef.current && Object.keys(stockWatcherInputs).length === 0) {
				uniqueOperationsRef.current = true
				dispatch(actionUpdateGlobalFlags({ "internal:stocks:hasReceivedFirstSync": true }))
				dispatch(actionUpdateGlobalFlags({ "internal:dividends:hasReceivedFirstSync": true }))
			}
		}


		// If the WebSocket is not connected, we will clear the intervals
		if (!isWSConnected) {
			uniqueOperationsRef.current = false

			// Stocks
			if (intervalStocksRef.current) {
				window.clearInterval(intervalStocksRef.current)
				intervalStocksRef.current = null
			}

			// Dividends
			if (intervalDividendRef.current) {
				window.clearInterval(intervalDividendRef.current)
				intervalDividendRef.current = null
			}

		}

		return () => {
			// Clear all intervals on unmount
			if (intervalStocksRef.current) {
				window.clearInterval(intervalStocksRef.current)
				intervalStocksRef.current = null
			}

			if (intervalDividendRef.current) {
				window.clearInterval(intervalDividendRef.current)
				intervalDividendRef.current = null
			}

		}

	}, [isWSConnected, flags, stockWatcherInputs])

	/** @Constants */

	return {}
}

export default useStoreStockManager;