import { Container, SelectInput, TextInput, DateInput, CustomInputLabel, NumberInput, ColorInput } from './TemplateDrawer'

export const TemplateDrawer = {
	Container,
	SelectInput,
	TextInput,
	DateInput,
	CustomInputLabel,
	NumberInput,
	ColorInput
}
