/* eslint-disable indent */
export const getPreviousWeekday = (date: Date): Date => {

	switch (date.getDay()) {
		case 0:
			date.setDate(date.getDate() - 2)
			break
		case 6:
			date.setDate(date.getDate() - 1)
			break
		default:
			break
	}

	return date
}