import { ISiteLayoutState, IVaultState } from "store/slices";


export interface IStockInformationResponse {
  symbol: string;
  currentPrice: number;
  currency: string;
}

export interface IBackendMarketRecord {
  id: string;
  createdAt: string;
  updatedAt: string;

  ticker: string;
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  adjClose: number;
  volume: number;
}

export interface IBackendMarketDividend {
  id: string;
  createdAt: string;
  updatedAt: string;

  ticker: string;
  date: string;
  dividends: number;
}

export interface IBackendUser {
  id: string
  createdAt: string
  updatedAt: string
  level: IBackendUserLevel
  rUsername: string
  stripeCustomerId?: string
  flags: IBackendUserFlags
}

export enum IBackendUserLevel {
  ULRegular = 0,
  ULPremium = 1,
  ULPremiumLifeTime = 2
}

export interface IBackendUserFlags {
  hasHadMembership: boolean
}


export interface IBackendStore {
  id: string
  createdAt: string
  updatedAt: string
  userId: string
  version: string
  siteLayout: ISiteLayoutState
  vault: IVaultState
}

export enum WSSActionTypes {
  Alive = "ALIVE",
  RequestUser = "REQUEST_USER",
  SaveStore = "SAVE_STORE",
  RequestStore = "REQUEST_STORE",
  RequestStocks = "REQUEST_STOCKS",
  RequestCurrencies = "REQUEST_CURRENCIES",
  RequestDividends = "REQUEST_DIVIDENDS"
}

export enum WSSResponseTypes {
  FullfilledRequestUser = "FULFILLED_REQUEST_USER",
  FullfilledSaveStore = "FULFILLED_SAVE_STORE",
  FulfilledRequestStore = "FULFILLED_REQUEST_STORE",
  FulfilledRequestStocks = "FULFILLED_REQUEST_STOCKS",
  FulfilledRequestCurrencies = "FULFILLED_REQUEST_CURRENCIES",
  FulfilledRequestDividends = "FULFILLED_REQUEST_DIVIDENDS"
}

export interface WSSJSONResponseMessage<T> {
  type: WSSResponseTypes
  data: T
}