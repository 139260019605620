import React from 'react'
import numeral from 'numeral';
import moment from 'moment-timezone';
import { Typography } from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Edit as EditIcon, Archive as ArchiveIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';

import { StyledDataGrid } from 'components/styled';
import { IStatisticsCurrency, IVaultAsset } from 'store/slices';

import useModule from './useModule.hook';
import { useIntl } from 'react-intl';

export interface AssetsTableProps {
	defaultMode: boolean
	rows?: IVaultAsset[]
	type?: 'byCurrency' | 'all'
	ifCurrency?: IStatisticsCurrency
}

export const AssetsTable: React.FC<AssetsTableProps> = (props) => {
	const intl = useIntl()
	const { flags, local, methods } = useModule(props)

	const columns: GridColDef<IVaultAsset>[] = [
		{
			field: 'actions', headerName: '', headerAlign: 'center', align: 'right', width: 50, editable: false, type: 'actions', getActions: (params) => [
				<GridActionsCellItem
					key="edit"
					icon={<EditIcon color="primary" />}
					disabled={!flags.isInternetConnected || methods.isAssetRowDisabled(params.row.id)}
					onClick={() => methods.handleEditAsset(params.row.id)}
					label={intl.formatMessage({ defaultMessage: 'Edit Asset', id: 'assetsTable.action.edit' })}
					showInMenu
				/>,
				<GridActionsCellItem
					key="archive"
					icon={<ArchiveIcon color="primary" />}
					disabled={!flags.isInternetConnected || methods.isAssetRowDisabled(params.row.id)}
					onClick={() => methods.handleArchiveAsset(params.row.id)}
					label={intl.formatMessage({ defaultMessage: 'Archive Asset', id: 'assetsTable.action.archive' })}
					showInMenu
				/>,
				<GridActionsCellItem
					key="delete"
					icon={<DeleteForeverIcon color="error" />}
					disabled={!flags.isInternetConnected || methods.isAssetRowDisabled(params.row.id)}
					onClick={() => methods.handleDeleteAsset(params.row.id)}
					label={intl.formatMessage({ defaultMessage: 'Delete Asset', id: 'assetsTable.action.delete' })}
					showInMenu
				/>,
			]
		},
		{
			field: 'name',
			headerName: intl.formatMessage({ defaultMessage: 'Name', id: 'assetsTable.header.name' }),
			headerAlign: 'left',
			width: local.isMobile ? 170 : 150,
			editable: true,
			renderCell: (params) => {
				const valueToRender = params.value
				return (methods.isAssetRowDisabled(params.row.id) ? (<div style={{ filter: "blur(3px)" }}>{valueToRender}</div>) : valueToRender)
			}
		},
		{
			field: 'currentValue',
			headerName: intl.formatMessage({ defaultMessage: 'Current Value', id: 'assetsTable.header.currentValue' }),
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 200 : 150,
			renderCell: (params) => {
				const valueToRender = `${numeral(params.value).format('$0,000.00')} ${params.row.currency}`
				return (methods.isAssetRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
		{
			field: 'valueAtBuy',
			headerName: intl.formatMessage({ defaultMessage: 'Value At Buy', id: 'assetsTable.header.valueAtBuy' }),
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 200 : 150,
			renderCell: (params) => {
				const valueToRender = `${numeral(params.value).format('$0,000.00')} ${params.row.currency}`
				return (methods.isAssetRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
		{
			field: 'currentYoYADRate',
			headerName: intl.formatMessage({ defaultMessage: 'Current APR/DPR', id: 'assetsTable.header.currentYoYADRate' }),
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 220 : 160,
			renderCell: (params) => {
				const value = (((params.row.currentValue * 100) / params.row.valueAtBuy) / 100) - 1
				const valueToRender = <Typography variant="h4" fontWeight="medium" sx={{ color: value > 0 ? 'success.main' : 'error.main', fontSize: 'inherit', }} >{numeral(value).format("0.00%")}</Typography>
				return (methods.isAssetRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
		{
			field: 'yoYADRate',
			headerName: intl.formatMessage({ defaultMessage: 'Expected YoY APR/DPR', id: 'assetsTable.header.yoYADRate' }),
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 250 : 200,
			renderCell: (params) => {
				const valueToRender = numeral(params.value).format('0.00%')
				return (methods.isAssetRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
		{
			field: 'acquiredAt',
			headerName: intl.formatMessage({ defaultMessage: 'Date of Acquisition', id: 'assetsTable.header.acquiredAt' }),
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 250 : 150,
			type: 'date',
			valueFormatter: (params) => moment(params.value).format("LL"),
			renderCell: (params) => {
				const valueToRender = moment(params.value).format("LL")
				return (methods.isAssetRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
	];

	return (
		<StyledDataGrid
			density='compact'
			sx={{ borderRadius: '5px' }}
			rows={local.rows}
			columns={columns}
			initialState={{
				pagination: {
					paginationModel: { page: 0, pageSize: 10 },
				},
				sorting: {
					sortModel: [
						{
							field: 'currentValue',
							sort: 'desc'
						}
					]
				}
			}}
			pageSizeOptions={[10, 20, 30]}
			disableRowSelectionOnClick
		/>
	)
}