export interface ServerV1OKResponse<T> {
	code: number
	data: T | null
	count: unknown
}
export interface ServerV1ErrorResponse {
	code: number
	error: string | null
	message: string | null
}

export function isServerError<T>(payload: ServerV1OKResponse<T> | ServerV1ErrorResponse): payload is ServerV1ErrorResponse {
	return (payload as ServerV1ErrorResponse).error !== undefined;
}