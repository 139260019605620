import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: '0.9rem',
	fontWeight: 400,
	minHeight: "150px",
	WebkitFontSmoothing: 'auto',
	letterSpacing: 'normal',
	padding: "10px 20px 10px 20px",
	backgroundColor: theme.palette.background.paper,
	border: "1px solid hsl(240, 3.7%, 15.9%)",
	'& .MuiDataGrid-columnHeaderTitle': {
		color: theme.palette.text.secondary,
	},
	'& .MuiDataGrid-columnsContainer': {
	},
	'& .MuiDataGrid-iconSeparator': {
		display: 'none',
	},
	'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
		borderBottom: `1px solid hsl(240, 3.7%, 15.9%)`,
	},
	'& .MuiDataGrid-cell': {
		color:
			theme.palette.text.primary
	},
	'& .MuiPaginationItem-root': {
		border: 'none',
		borderRadius: 0,
	},
})) as typeof DataGrid;
