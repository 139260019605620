import React, { useMemo, useState } from 'react'
import { create, InstanceProps } from 'react-modal-promise';
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@mui/material';
import { FlagCircle as FlagCircleIcon, Wallet as WalletIcon, AccountBalance as AccountBalanceIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';

import { IVaultAccountAutoDistributionConfiguration, IVaultAccountInput } from 'store/slices';
import { TemplateDrawer } from 'components/layout';

import { useAppSelector } from 'store';
import { StyledDataGrid } from 'components/styled';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { AccountTypeFields } from './CreateAccountDrawer';
import { IntlShape } from 'react-intl';
import numeral from 'numeral';


const AddConfigurationDialog = (props: InstanceProps<{ account: string, quantity: number }, undefined> & { intl: IntlShape, account: IVaultAccountInput, maxQuantity: number, alreadySelectedKeys: string[] }) => {
	const { isOpen, onResolve, onReject, intl, account, maxQuantity, alreadySelectedKeys } = props
	const accounts = useAppSelector((state) => state.vault.accounts)

	const [config, setconfig] = useState({
		account: '',
		quantity: account.autoDistributionConfigurationType === 'PERCENTAGE' && maxQuantity > 0 ? maxQuantity : 0,
	})

	const handleSetConfig = (value: Partial<typeof config>) => {
		setconfig((prev) => ({ ...prev, ...value }))
	}

	return (
		<Dialog open={isOpen} onClose={() => onReject()}>
			<DialogTitle>
				{intl.formatMessage({ defaultMessage: 'Add Distribution Configuration', id: 'createAccountDrawer.autoDistribution.configuration.add.title' })}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', gap: "14px", flexDirection: 'column' }}>
				<TemplateDrawer.SelectInput
					label={intl.formatMessage({ defaultMessage: 'Select the account:', id: 'createAccountDrawer.autoDistribution.methodType.label' })}
					placeholder={intl.formatMessage({ defaultMessage: 'Select the distribution method type:', id: 'createAccountDrawer.autoDistribution.methodType.placeholder' })}
					required
					value={config.account}
					onChange={(value) => handleSetConfig({ account: value })}
					customOptions={Object.keys(accounts).filter(key => !alreadySelectedKeys.includes(key)).map((id) => {
						const optAccount = accounts[id]

						return (
							<MenuItem key={id} value={optAccount.id} sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography variant='h3' fontWeight="medium">
									{optAccount.type === 'CHECKINGS' && (<WalletIcon sx={{ position: 'relative', top: 6, mr: 1, color: 'text.secondary' }} />)}
									{optAccount.type === 'GOAL' && (<FlagCircleIcon sx={{ position: 'relative', top: 6, mr: 1, color: 'text.secondary' }} />)}
									{optAccount.type === 'STOCKS_BALANCE' && (<AccountBalanceIcon sx={{ position: 'relative', top: 6, mr: 1, color: 'text.secondary' }} />)}
									{optAccount.name}
								</Typography>
								<Typography component="span" variant="body1" fontWeight="regular">
									{optAccount.currency.toUpperCase()}
								</Typography>
								{account.autoDistributionConfigurationType === 'AMOUNT' && account.currency !== optAccount.currency && (
									<Typography component="span" variant="body1" fontWeight="regular">
										{`(${intl.formatMessage({ defaultMessage: 'A conversion will be made at the moment of the transaction', id: 'createAccountDrawer.autoDistribution.customAmount.warningDifferentCurrency' })})`}
									</Typography>
								)}
							</MenuItem>
						)
					})}
				/>
				{account.autoDistributionConfigurationType === 'AMOUNT' && (
					<TemplateDrawer.NumberInput
						label={intl.formatMessage({ defaultMessage: 'Enter the amount:', id: 'createAccountDrawer.typeAmount.quantity.label' })}
						value={config.quantity || null}
						onChange={(value) => handleSetConfig({ quantity: value })}
						adornment='$'
						min={0.0}
						placeholder='3,000'
					/>
				)}
				{account.autoDistributionConfigurationType === 'PERCENTAGE' && (
					<TemplateDrawer.NumberInput
						label={intl.formatMessage({ defaultMessage: 'Enter the percentage:', id: 'createAccountDrawer.typePercentage.quantity.label' })}
						value={config.quantity || null}
						onChange={(value) => handleSetConfig({ quantity: value })}
						adornment='%'
						disableDecimalScale
						min={0.0}
						max={maxQuantity || 100}
						placeholder='15'
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" onClick={() => onReject()}>
					{intl.formatMessage({ defaultMessage: 'Cancel', id: 'createAccountDrawer.autoDistribution.configuration.add.cancel.label' })}
				</Button>
				<Button variant='contained' color="primary" onClick={() => onResolve(config)} disabled={!config.account || !config.quantity}>
					{intl.formatMessage({ defaultMessage: 'Add', id: 'createAccountDrawer.autoDistribution.configuration.add.confirm.label' })}
				</Button>
			</DialogActions>
		</Dialog >
	)
}

export const CreatableAddConfigurationDialog = create(AddConfigurationDialog)

export const AccountTypeAutoDistributionFields: React.FC<AccountTypeFields> = ({ intl, account, methods }) => {
	const accounts = useAppSelector((state) => state.vault.accounts)


	const accumulatedConfigurationAmount = useMemo(() => {
		return Object.values(account.autoDistributionConfigurations || {}).reduce((acc, curr) => acc + curr.quantity, 0)
	}, [account.autoDistributionConfigurations])

	const isThereDifferentCurrencyConfigurations = useMemo(() => {
		if (Object.values(account.autoDistributionConfigurationType || {}).length === 0) return false;

		return Object.values(account.autoDistributionConfigurations || {}).some((config) => {
			const configAccount = accounts[config.relatedEntityId]
			return configAccount.currency !== account.currency
		})
	}, [account.autoDistributionConfigurations])


	const handleAddConfiguration = async () => {
		try {
			const data = await CreatableAddConfigurationDialog({
				intl,
				account,
				maxQuantity: 100 - accumulatedConfigurationAmount,
				alreadySelectedKeys: Object.values(account.autoDistributionConfigurations || {}).map((config) => config.relatedEntityId)
			})

			methods.handleUpdateAccount({
				autoDistributionConfigurations: {
					...account.autoDistributionConfigurations,
					[data.account]: {
						relatedEntityId: data.account,
						quantity: data.quantity,
					}
				}
			})
		} catch (error) {
			return;
		}
	}

	const handleDeleteConfiguration = async (id: string) => {
		const configs = { ...account.autoDistributionConfigurations }
		delete configs[id]
		methods.handleUpdateAccount({
			autoDistributionConfigurations: configs
		})
	}


	const columns: GridColDef<IVaultAccountAutoDistributionConfiguration>[] = [
		{
			field: 'actions', headerName: '', headerAlign: 'center', align: 'right', width: 50, editable: false, type: 'actions', getActions: (params) => [
				<GridActionsCellItem
					key="delete"
					icon={<DeleteForeverIcon color="error" />}
					onClick={() => handleDeleteConfiguration(params.row.relatedEntityId)}
					label={intl.formatMessage({ defaultMessage: 'Delete Asset', id: 'autoDistributionConfigurationTable.action.delete' })}
				/>,
			]
		},
		{
			field: 'relatedEntityId',
			headerName: intl.formatMessage({ defaultMessage: 'Account', id: 'autoDistributionConfigurationTable.header.account' }),
			headerAlign: 'left',
			width: 250,
			editable: true,
			renderCell: (params) => {
				const rowAccount = accounts[params.value]
				return rowAccount.name
			},
		},
		{
			field: 'quantity',
			headerName: account.autoDistributionConfigurationType === 'AMOUNT' ? intl.formatMessage({ defaultMessage: 'Amount to Distribute', id: 'autoDistributionConfigurationTable.header.amount.quantity' }) : intl.formatMessage({ defaultMessage: 'Percentage to Distribute', id: 'autoDistributionConfigurationTable.header.percentage.quantity' }),
			type: 'number',
			headerAlign: 'left',
			align: 'left',
			width: 250,
			renderCell: (params) => {
				return account.autoDistributionConfigurationType === 'AMOUNT' ? numeral(params.value).format("$0,000.00") : numeral(params.value / 100).format("0.00%")
			},
		},
	];

	return (
		<>
			<TemplateDrawer.SelectInput
				label={intl.formatMessage({ defaultMessage: 'Select the distribution method type:', id: 'createAccountDrawer.autoDistribution.methodType.label' })}
				placeholder={intl.formatMessage({ defaultMessage: 'Select the distribution method type:', id: 'createAccountDrawer.autoDistribution.methodType.placeholder' })}
				required
				value={account.autoDistributionConfigurationType}
				disabled={Object.keys(account.autoDistributionConfigurations || {}).length > 0}
				onChange={(value) => methods.handleUpdateAccount({ autoDistributionConfigurationType: value })}
				options={[
					{ value: 'PERCENTAGE', label: intl.formatMessage({ defaultMessage: 'Percentage', id: 'createAccountDrawer.autoDistribution.methodType.percentage' }) },
					{ value: 'AMOUNT', label: intl.formatMessage({ defaultMessage: 'Custom Amount', id: 'createAccountDrawer.autoDistribution.methodType.amount' }) },
				]}
			/>
			{account.autoDistributionConfigurationType && (
				<Box sx={{ mb: 10 }}>
					<Typography variant="h4" fontWeight="medium" sx={{ mb: 2 }}>
						{intl.formatMessage({ defaultMessage: 'Distribution Configuration', id: 'createAccountDrawer.autoDistribution.configuration' })}
						<Typography component="span" sx={{ color: 'error.main', fontWeight: 'inherit', fontSize: 'inherit' }}>{" *"}</Typography>
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: "12px" }}>
						<Button variant="outlined" color="primary" onClick={handleAddConfiguration} disabled={account.autoDistributionConfigurationType === 'PERCENTAGE' && accumulatedConfigurationAmount >= 100}>
							{intl.formatMessage({ defaultMessage: 'Add Configuration', id: 'createAccountDrawer.autoDistribution.configuration.add' })}
						</Button>
						{Object.values(account.autoDistributionConfigurations || {}).length > 0 && (
							<>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: "12px" }}>
									<Box>
										{account.autoDistributionConfigurationType === 'AMOUNT' && (
											<Typography variant='body1' fontWeight="regular" sx={{ color: 'text.secondary' }}>
												{intl.formatMessage({ defaultMessage: 'Total amount to distribute: {amount}', id: 'createAccountDrawer.autoDistribution.configuration.totalAmount' }, { amount: numeral(accumulatedConfigurationAmount).format("$0,000.00") })}
											</Typography>
										)}
										{account.autoDistributionConfigurationType === 'PERCENTAGE' && (
											<>
												<Typography variant='body1' fontWeight="regular" sx={{ color: 'text.secondary' }}>
													{intl.formatMessage({ defaultMessage: 'Percentage left to assign: {amount}', id: 'createAccountDrawer.autoDistribution.configuration.percetageLeftToAssign' }, { amount: numeral((100 - accumulatedConfigurationAmount) / 100).format("0.00%") })}
												</Typography>
												<Typography variant='body1' fontWeight="regular" sx={{ color: 'text.secondary' }}>
													{intl.formatMessage({ defaultMessage: 'Total percentage to distribute: {amount}', id: 'createAccountDrawer.autoDistribution.configuration.totalPercentage' }, { amount: numeral(accumulatedConfigurationAmount / 100).format("0.00%") })}
												</Typography>
											</>
										)}
									</Box>
									<StyledDataGrid
										density='compact'
										sx={{ borderRadius: '5px' }}
										rows={Object.values(account.autoDistributionConfigurations || {})}
										columns={columns}
										getRowId={(row) => row.relatedEntityId}
										initialState={{
											pagination: {
												paginationModel: { page: 0, pageSize: 10 },
											},
											sorting: {
												sortModel: [
													{
														field: 'currentValue',
														sort: 'desc'
													}
												]
											}
										}}
										pageSizeOptions={[10, 20, 30]}
										disableRowSelectionOnClick
									/>
								</Box>
							</>
						)}
						{Object.values(account.autoDistributionConfigurations || {}).length === 0 && (
							<Alert variant="outlined" severity='error'>
								<AlertTitle>
									{intl.formatMessage({ defaultMessage: 'No distribution configurations added. Please add one', id: 'createAccountDrawer.autoDistribution.configuration.empty' })}
								</AlertTitle>
							</Alert>
						)}
						{account.autoDistributionConfigurationType === 'PERCENTAGE' && Object.values(account.autoDistributionConfigurations || {}).length > 0 && accumulatedConfigurationAmount < 100 && (
							<Alert variant="filled" severity='error'>
								<AlertTitle>
									{intl.formatMessage({ defaultMessage: 'The total percentage to distribute must be 100%', id: 'createAccountDrawer.autoDistribution.configuration.percentageNot100' })}
								</AlertTitle>
							</Alert>
						)}
						{account.autoDistributionConfigurationType === 'AMOUNT' && (
							<Alert variant="outlined" severity='info'>
								<AlertTitle>
									{intl.formatMessage({ defaultMessage: 'In order to trigger a distribution with this type of method, you\'ll be required to transact the exact accumulated amount (this will be done automatically), otherwise it won\'t work ', id: 'createAccountDrawer.autoDistribution.configuration.amount.requiredAmount' })}
								</AlertTitle>
							</Alert>
						)}
						{account.autoDistributionConfigurationType === 'PERCENTAGE' && isThereDifferentCurrencyConfigurations && (
							<Alert variant="outlined" severity='info'>
								<AlertTitle>
									{intl.formatMessage({ defaultMessage: 'There are account configurations with different currencies than the account\'s selected one', id: 'createAccountDrawer.autoDistribution.configuration.differentCurrencies' })}
								</AlertTitle>
								<Typography variant='body1' fontWeight="regular" sx={{ color: 'text.secondary' }}>
									{intl.formatMessage({ defaultMessage: 'A currency exchange will be made at the transaction point in time.', id: 'createAccountTransaction.autoDistribution.currencyMismatch.solution' })}
								</Typography>
							</Alert>
						)}
					</Box>
				</Box>
			)}
		</>
	)
}
