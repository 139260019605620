import React from 'react'
import numeral from 'numeral';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Edit as EditIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';

import { StyledDataGrid } from 'components/styled';
import { IVaultStockHistoryOperationType } from 'store/slices';

import useModule from './useModule.hook';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';

export interface StockHistoryTableRowContent {
	id: string
	operationType: IVaultStockHistoryOperationType
	shares: number
	priceAtOperation: number
	valueAtOperation: number
	date: Date
}

export interface IStockHistoryTableProps {
	ticker: string
}

export const StocksHistoryTable: React.FC<IStockHistoryTableProps> = (props) => {
	const intl = useIntl()
	const { flags, local, methods } = useModule(props)

	const columns: GridColDef<StockHistoryTableRowContent>[] = [
		{
			field: 'actions', headerName: '', headerAlign: 'center', align: 'right', width: 80, editable: false, type: 'actions', getActions: (params) => [
				<GridActionsCellItem key="edit" icon={<EditIcon {...(methods.isRowDisabled(params.row.id) ? {} : { color: "primary" })} />} disabled={!flags.isInternetConnected || methods.isRowDisabled(params.row.id)} onClick={() => methods.handleEditStockOperation(params.row.id)} label={intl.formatMessage({ defaultMessage: 'Edit Operation', id: 'stocks.components.historyTable.editOperation' })} />,
				<GridActionsCellItem key="delete" icon={<DeleteForeverIcon {...(methods.isRowDisabled(params.row.id) ? {} : { color: "error" })} />} disabled={!flags.isInternetConnected || methods.isRowDisabled(params.row.id)} onClick={() => methods.handleDeleteStockOperation(params.row.id)} label={intl.formatMessage({ defaultMessage: 'Delete Operation', id: 'stocks.components.historyTable.deleteOperation' })} />,
			]
		},
		{
			field: 'operationType',
			headerName: intl.formatMessage({ defaultMessage: 'Type', id: 'stocks.components.historyTable.header.type' }),
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 120 : 100,
			editable: false,
			valueFormatter: (params) => `${params.value === IVaultStockHistoryOperationType.BUY ? intl.formatMessage({ defaultMessage: 'Buy', id: 'global.operation.buy' }) : intl.formatMessage({ defaultMessage: 'Sell', id: 'global.operation.sell' })}`,
			renderCell: (params) => {
				const valueToRender = `${params.value === IVaultStockHistoryOperationType.BUY ? intl.formatMessage({ defaultMessage: 'Buy', id: 'global.operation.buy' }) : intl.formatMessage({ defaultMessage: 'Sell', id: 'global.operation.sell' })}`;
				return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			}
		},
		{
			field: 'shares',
			headerName: intl.formatMessage({ defaultMessage: 'Shares', id: 'stocks.components.historyTable.header.shares' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 150 : 100,
			editable: false,
			type: 'number',
			renderCell: (params) => { const valueToRender = params.value; return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'priceAtOperation',
			headerName: intl.formatMessage({ defaultMessage: 'Price @ Operation', id: 'stocks.components.historyTable.header.priceAtOperation' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 200 : 150,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => { const valueToRender = numeral(params.value).format("$0,000.00"); return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'valueAtOperation',
			headerName: intl.formatMessage({ defaultMessage: 'Value of Operation', id: 'stocks.components.historyTable.header.valueOfOperation' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 225 : 150,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => { const valueToRender = numeral(params.value).format("$0,000.00"); return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'date',
			headerName: intl.formatMessage({ defaultMessage: 'Date of Operation', id: 'stocks.components.historyTable.header.date' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 225 : 200,
			editable: false,
			type: 'date',
			valueFormatter: (params) => moment(params.value).format("LL"),
			renderCell: (params) => { const valueToRender = moment(params.value).format("LL"); return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
	];

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: "12px" }}>
			{flags.isTherePremiumRecords && !local.isPremium && (
				<div key={"warnings"}>
					<Alert variant="standard" severity="warning">
						<AlertTitle>
							{intl.formatMessage({ defaultMessage: 'Your Premium subscription has finished!', id: 'stocks.records.subscriptionFinished' })}
						</AlertTitle>
						{intl.formatMessage({ defaultMessage: 'Some of your records for this stock were created under your previous Premium subscription to eStacks.', id: 'stocks.records.createdUnderPremium' })}
						<br />
						{intl.formatMessage({ defaultMessage: 'You need to upgrade to Premium again to be able to track them.', id: 'stocks.records.youNeedToUpgrade' })}
						<br />
						<br />
						<Typography component="a" sx={{ cursor: 'pointer', color: 'text.primary' }} href={`${process.env.REACT_APP_LANDING_DOMAIN}/sign-in`} target="_blank" rel="noreferrer">
							{intl.formatMessage({ defaultMessage: 'Click here to manage your subscription', id: 'stocks.records.upgradeSubscriptionLink' })}
						</Typography>
					</Alert>
				</div>
			)}
			<StyledDataGrid
				density='compact'
				sx={{ borderRadius: '5px' }}
				rows={local.rows}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 10 },
					},
					sorting: {
						sortModel: [
							{
								field: 'date',
								sort: 'desc'
							}
						]
					}
				}}
				pageSizeOptions={[10, 20, 30]}
				disableRowSelectionOnClick
			/>
		</Box>
	)
}