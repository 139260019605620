import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { IProcessQueueTaskNames, ISessionWSStatus, actionEnqueueProcess, actionEnqueueUniqueProcess } from "store/slices";
import useStoreStockManager from "./useStoreStockManager.hook";
import { IBackendUserLevel } from "interfaces";

/** OPTIONS */

const INTERVAL_HEARTBEAT = 15000 // 15 seconds

const PREMIUM_STOCKS_FETCH_RATE = 1_800_000 // 30 minutes
const FREE_STOCKS_FETCH_RATE = 3_600_000 // 1 hour

const useModule = () => {
	/** @Globals */
	const dispatch = useAppDispatch()
	const intervalHeartbeatRef = useRef<number | null>(null)

	const uniqueOperationsRef = useRef<boolean>(false)
	const initialOperationsRef = useRef<boolean>(false)

	/** @Selectors */
	const session = useAppSelector(state => state.session)
	const globalFlags = useAppSelector(state => state.flags.global)
	const appFlags = useAppSelector(state => state.flags.app)
	const isWSConnected = useAppSelector(state => state.session.isWebSocketConnected)
	const currentWebSocketConnectionStatus = useAppSelector(state => state.session.webSocketConnectionStatus)
	const previousWebSocketConnectionStatus = useAppSelector(state => state.session.previousWebSocketConnectionStatus)

	/** @States */

	/** @Functions */

	/** @Effects */
	// This effect is in charge of requesting the store and user data only when a new connection or reconnection is registered
	useEffect(() => {
		if (globalFlags["internal:store:hasReceivedFirstSync"]) return;
		if (initialOperationsRef.current) return;

		// Handle an actual new connection or reconnection
		if (currentWebSocketConnectionStatus === ISessionWSStatus.CONNECTED && (previousWebSocketConnectionStatus === ISessionWSStatus.LOADING || previousWebSocketConnectionStatus === ISessionWSStatus.DISCONNECTED)) {
			initialOperationsRef.current = true
			dispatch(actionEnqueueProcess({
				processName: IProcessQueueTaskNames.REQUEST_STORE,
			}))
			dispatch(actionEnqueueProcess({
				processName: IProcessQueueTaskNames.REQUEST_USER,
			}))
		}

	}, [currentWebSocketConnectionStatus, previousWebSocketConnectionStatus, globalFlags])

	// The following effect will control the setInterval for real-time data updates
	useEffect(() => {
		// If the WebSocket is connected, we will set the intervals
		if (isWSConnected) {

			// Heartbeat
			if (!intervalHeartbeatRef.current) {
				intervalHeartbeatRef.current = window.setInterval(() => {
					dispatch(actionEnqueueUniqueProcess({
						processName: IProcessQueueTaskNames.ALIVE,
					}))
				}, INTERVAL_HEARTBEAT)
			}
		}

		// If the WebSocket is not connected, we will clear the intervals
		if (!isWSConnected) {

			// Heartbeat
			if (intervalHeartbeatRef.current) {
				window.clearInterval(intervalHeartbeatRef.current)
				intervalHeartbeatRef.current = null
			}

			// Release the unique operations controller
			uniqueOperationsRef.current = false
			// Release the initial operations controller
			initialOperationsRef.current = false
		}

		return () => {
			// Clear all intervals on unmount
			if (intervalHeartbeatRef.current) {
				window.clearInterval(intervalHeartbeatRef.current)
				intervalHeartbeatRef.current = null
			}

		}

	}, [isWSConnected])

	// The following effect will control the operations that only need to run one time
	useEffect(() => {
		if (!isWSConnected) return
		if (uniqueOperationsRef.current) return

		if (currentWebSocketConnectionStatus === ISessionWSStatus.CONNECTED && (previousWebSocketConnectionStatus === ISessionWSStatus.LOADING || previousWebSocketConnectionStatus === ISessionWSStatus.DISCONNECTED)) {
			// Mark the unique operations as done
			uniqueOperationsRef.current = true

			// Request CURRENCY EXCHANGE RATES
			dispatch(actionEnqueueUniqueProcess({
				processName: IProcessQueueTaskNames.REQUEST_CURRENCIES,
				payload: {},
			}))
		}


	}, [isWSConnected, globalFlags])

	/** @Constants */

	/** Secondary Hooks */
	useStoreStockManager(session.level === IBackendUserLevel.ULRegular ? FREE_STOCKS_FETCH_RATE : PREMIUM_STOCKS_FETCH_RATE)

	return {
		flags: {
			showLoadingScreen: appFlags["internal:hasRequiredDataBeenFetched"],
		},
		localData: {
			flagsPercentage: Object.values(globalFlags || {}).filter((value) => value === true).length / Object.values(globalFlags || {}).length,
		}
	}
}

export default useModule;