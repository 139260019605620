import React from 'react'
import { Typography } from '@mui/material';
import numeral from 'numeral';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { ManageSearch as ManageSearchIcon } from '@mui/icons-material';

import { StyledDataGrid } from 'components/styled';

import useModule from './useModule.hook';
import { useIntl } from 'react-intl';

export interface StockTableRowContent {
	id: string
	ticker: string
	shares: number
	currentValue: number
	lastPrice: number
	totalDividendIncome: number
	todaysGain: number
	todaysGainPercentage: number
	totalGain: number
	totalGainPercentage: number
	totalReturn: number
	actions: string
}

export const StocksTable = () => {
	const intl = useIntl()
	const { local, methods } = useModule()

	const columns: GridColDef<StockTableRowContent>[] = [
		{
			field: 'actions', headerName: '', headerAlign: 'center', align: 'right', width: 50, editable: false, type: 'actions', getActions: (params) => [
				<GridActionsCellItem key="manage" icon={<ManageSearchIcon {...(methods.isRowDisabled(params.row.id) ? {} : { color: "primary" })} />} onClick={() => methods.handleManageStock(params.row.ticker)} label={intl.formatMessage({ defaultMessage: 'Manage', id: 'stocks.components.table.manageStock' })} disabled={methods.isRowDisabled(params.row.id)} />,
			]
		},
		{
			field: 'ticker',
			headerName: intl.formatMessage({ defaultMessage: 'Ticker', id: 'stocks.components.table.header.ticker' }),
			headerAlign: 'left',
			align: 'left',
			width: local.isMobile ? 130 : 100,
			editable: false,
			renderCell: (params) => { const valueToRender = params.value; return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'shares',
			headerName: intl.formatMessage({ defaultMessage: 'Shares', id: 'stocks.components.table.header.shares' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 130 : 100,
			editable: false,
			type: 'number',
			renderCell: (params) => { const valueToRender = params.value; return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'currentValue',
			headerName: intl.formatMessage({ defaultMessage: 'Current Value', id: 'stocks.components.table.header.currentvalue' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 200 : 150,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => { const valueToRender = numeral(params.value).format("$0,000.00"); return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'lastPrice',
			headerName: intl.formatMessage({ defaultMessage: 'Last Price p/Share', id: 'stocks.components.table.header.lastPrice' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 200 : 150,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => { const valueToRender = numeral(params.value).format("$0,000.00"); return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender) }
		},
		{
			field: 'todaysGain',
			headerName: intl.formatMessage({ defaultMessage: 'Today\'s Gain (Unrealized)', id: 'stocks.components.table.header.todaysGain' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 275 : 200,
			editable: false,
			type: 'number',
			renderCell: (params) => {
				const valueToRender = (<Typography variant="h4" fontWeight="regular" sx={{ color: params.row.todaysGainPercentage > 0 ? 'success.main' : 'error.main', fontSize: 'inherit', }} >{`${numeral(params.row.todaysGainPercentage).format("0.00%")} (${params.row.todaysGain > 0 ? '+' : ''}${numeral(params.row.todaysGain).format("$0,000.00")})`}</Typography>)
				return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			},
		},
		{
			field: 'totalGain',
			headerName: intl.formatMessage({ defaultMessage: 'Total Gain (Unrealized)', id: 'stocks.components.table.header.totalGain' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 250 : 200,
			editable: false,
			type: 'number',
			renderCell: (params) => {
				const valueToRender = (<Typography variant="h4" fontWeight="regular" sx={{ color: params.row.totalGainPercentage > 0 ? 'success.main' : 'error.main', fontSize: 'inherit', }} >{`${numeral(params.row.totalGainPercentage).format("0.00%")} (${params.row.totalGain > 0 ? '+' : ''}${numeral(params.row.totalGain).format("$0,000.00")})`}</Typography>)
				return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			},
		},
		{
			field: 'totalDividendIncome',
			headerName: intl.formatMessage({ defaultMessage: 'Total Dividend Income', id: 'stocks.components.table.header.totalDividendIncome' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 250 : 200,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => {
				const valueToRender = numeral(params.value).format("$0,000.00")
				return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			},
		},
		{
			field: 'totalReturn',
			headerName: intl.formatMessage({ defaultMessage: 'Total Return', id: 'stocks.components.table.header.totalReturn' }),
			headerAlign: 'center',
			align: 'center',
			width: local.isMobile ? 200 : 150,
			editable: false,
			type: 'number',
			valueFormatter: (params) => numeral(params.value).format("$0,000.00"),
			renderCell: (params) => {
				const valueToRender = numeral(params.value).format("$0,000.00")
				return (methods.isRowDisabled(params.row.id) ? <div style={{ filter: "blur(3px)" }}>{valueToRender}</div> : valueToRender)
			},
		},
	];

	return (
		<StyledDataGrid
			density='compact'
			sx={{ borderRadius: '5px' }}
			rows={local.rows}
			columns={columns}
			initialState={{
				pagination: {
					paginationModel: { page: 0, pageSize: 10 },
				},
				sorting: {
					sortModel: [
						{
							field: 'currentValue',
							sort: 'desc'
						}
					]
				}
			}}
			pageSizeOptions={[10, 20, 30]}
			disableRowSelectionOnClick
		/>
	)
}