import axios from 'axios'
import { ServerV1ErrorResponse, ServerV1OKResponse } from 'interfaces';
export class APIService {

	static ROUTES = {
		V1: {
			USER: {
				MANAGEMENT: {
					DELETE: '/v1/user/management/delete',
				},
				AUTH: {
					SIGN_IN: '/v1/user/auth/sign-in',
					SIGN_UP: '/v1/user/auth/sign-up',
					CHECK: '/v1/user/auth/check',
				},
			}
		}
	}

	static async protected_post<T>(route: string, data: unknown, token: string): Promise<ServerV1OKResponse<T> | ServerV1ErrorResponse> {
		const domain = process.env.REACT_APP_HTTP_DOMAIN
		if (!domain) {
			return {
				code: 500,
				error: 'Internal Client Error',
				message: 'Something went wrong... [C01]'
			} as ServerV1ErrorResponse
		}

		try {
			const response = await axios.post<ServerV1OKResponse<T>>(domain + route, data, {
				headers: {
					'Authorization': token
				}
			});
			return response.data;
		} catch (error) {
			if (axios.isAxiosError<ServerV1ErrorResponse>(error)) {
				const response = error.response
				if (response) {
					return response.data
				}
			}

			return {
				code: 500,
				error: 'Internal Client Error',
				message: 'Something went wrong... [C02]'
			} as ServerV1ErrorResponse
		}
	}

	static async public_post<T>(route: string, data: unknown): Promise<ServerV1OKResponse<T> | ServerV1ErrorResponse> {
		const domain = process.env.REACT_APP_HTTP_DOMAIN
		if (!domain) {
			return {
				code: 500,
				error: 'Internal Client Error',
				message: 'Something went wrong... [C01]'
			} as ServerV1ErrorResponse
		}

		try {
			const response = await axios.post<ServerV1OKResponse<T>>(domain + route, data);
			return response.data;
		} catch (error) {
			if (axios.isAxiosError<ServerV1ErrorResponse>(error)) {
				const response = error.response
				if (response) {
					return response.data
				}
			}

			return {
				code: 500,
				error: 'Internal Client Error',
				message: 'Something went wrong... [C02]'
			} as ServerV1ErrorResponse
		}
	}
}