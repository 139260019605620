import _ from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { CreatableValidatorDialog } from "components/global/general";
import { IProcessQueueTaskNames, IVaultAsset, actionAssetDelete, actionAssetEdit, actionEnqueueUniqueProcess } from "store/slices";

import { AssetsTableProps } from "./AssetsTable";
import { CreatableEditAssetDrawer } from "./module/EditAssetDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { IBackendUserLevel } from "interfaces";

const useModule = (props: AssetsTableProps) => {
	/** @Globals */
	const intl = useIntl()
	const dispatch = useAppDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	/** @Selectors */
	const isInternetConnected = useAppSelector(state => state.session.isWebSocketConnected)
	const stateAssets = useAppSelector(state => state.vault.assets)
	const isPremium = useAppSelector(state => state.session.level !== IBackendUserLevel.ULRegular)
	const nonPremiumAssets = useAppSelector(state => state.statistics.assets.nonPremiumAssets)

	/** @States */
	const [rows, setrows] = useState<IVaultAsset[]>([])
	const [flags, setflags] = useState({
		isEnabled: true,
		isLoading: true,
		isThereAssets: false,
		isThereArchivedAssets: false,
	})

	/** @Functions */
	const updateFlags = (newFlags: Partial<typeof flags>) => {
		setflags((prev) => ({
			...prev,
			...newFlags
		}))
	}

	const handleEditAsset = (id: string) => {
		CreatableEditAssetDrawer({ assetId: id })
	}

	const handleArchiveAsset = async (id: string) => {
		try {
			await CreatableValidatorDialog({
				dialogTitle: intl.formatMessage({ defaultMessage: 'Archive Asset', id: 'assetsTable.dialog.validation.archive.title' }),
				dialogDescription: intl.formatMessage({ defaultMessage: 'Are you sure you want to archive this asset? You can always unarchive it later', id: 'assetsTable.dialog.validation.archive.description' }),
			})

			dispatch(actionAssetEdit({ id, isArchived: true }))
			dispatch(actionEnqueueUniqueProcess({ processName: IProcessQueueTaskNames.SAVE_STORE }))
			enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Asset successfully archived', id: 'assetsTable.archive.success' }), { variant: 'success', persist: false, preventDuplicate: false, autoHideDuration: 2000 })

		} finally {
			_
		}
	}

	const handleDeleteAsset = async (id: string) => {

		try {
			await CreatableValidatorDialog({
				dialogTitle: intl.formatMessage({ defaultMessage: 'Delete Asset', id: 'assetsTable.dialog.validation.delete.title' }),
				dialogDescription: intl.formatMessage({ defaultMessage: 'Are you sure you want to delete this asset? This is a permanent action', id: 'assetsTable.dialog.validation.delete.description' }),
			})

			dispatch(actionAssetDelete({ id }))
			dispatch(actionEnqueueUniqueProcess({ processName: IProcessQueueTaskNames.SAVE_STORE }))
			enqueueSnackbar(intl.formatMessage({ defaultMessage: 'Asset successfully deleted', id: 'assetsTable.delete.success' }), { variant: 'success', persist: false, preventDuplicate: false, autoHideDuration: 2000 })

		} finally {
			_
		}
	}

	const isAssetRowDisabled = (assetId: string) => {
		if (isPremium) {
			return false
		}

		return !nonPremiumAssets[assetId]
	}


	/** @Effects */
	useEffect(() => {
		if (props.defaultMode) {
			const nonArchivedAssets = Object.values(stateAssets).filter(asset => !asset.isArchived)
			const availableAssets = nonArchivedAssets.length > 0
			const archivedAssets = Object.values(stateAssets).filter(asset => asset.isArchived)

			if (props.type === 'all') {
				setrows(nonArchivedAssets)
			} else {
				const groupedAssets = _.groupBy(nonArchivedAssets, 'currency')
				setrows(groupedAssets?.[props.ifCurrency || ''] || [])
			}

			updateFlags({ isThereAssets: availableAssets, isThereArchivedAssets: archivedAssets.length > 0 })
		} else {
			setrows(props.rows || [])
		}

	}, [stateAssets, props])


	/** @Constants */

	return {
		flags: {
			isInternetConnected,
		},
		local: {
			rows,
			isMobile,
		},
		methods: {
			handleEditAsset,
			handleDeleteAsset,
			isAssetRowDisabled,
			handleArchiveAsset,
		}
	}
}

export default useModule;