import numeral from 'numeral';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react'
import { Box, LinearProgress, Typography } from '@mui/material';

import useModule from './useMainModule.hook'

import { ReactComponent as Logo1000 } from 'static/logo/Logo1000.svg'

export const StoreManager: React.FC<PropsWithChildren> = ({ children }) => {
	const { flags, localData } = useModule()
	const intl = useIntl()

	if (!flags.showLoadingScreen) {
		return (
			<motion.div key="storeManager-children" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: 0.4 }}>
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: "100vh" }}>
					<motion.div
						key="storeManager-logo"
						animate={{ scale: [1, 1.05, 1], opacity: [0.8, 1, 0.8] }}
						transition={{ ease: "easeInOut", duration: 1, repeat: Infinity }}
					>
						<Logo1000
							width="140px"
							height="140px"
							style={{ margin: 'auto', }}
						/>
					</motion.div>
					<Box sx={{ display: 'grid', height: '100%', width: '100%', placeContent: 'center', textAlign: 'center', zIndex: 1250, }}>
						<Typography sx={{ color: 'text.primary', m: 'auto', fontSize: '1.3rem', fontWeight: 400 }}>
							{`${intl.formatMessage({ defaultMessage: 'Synchronizing your data', id: 'sync.loading.synchronizing' })} (${numeral(localData.flagsPercentage).format("0.0%")})`}
						</Typography>
						<LinearProgress variant='buffer' value={localData.flagsPercentage * 100} valueBuffer={Math.min(localData.flagsPercentage * 100 + 25, 100)} />
						<Typography sx={{ m: 'auto', mt: "10px", color: 'text.secondary', fontSize: '0.8rem', fontWeight: 200 }}>
							{intl.formatMessage({ defaultMessage: 'Please wait a moment', id: 'sync.loading.pleaseWait' })}
						</Typography>
					</Box>
				</div>
			</motion.div>
		)
	}

	return (
		<motion.div key="storeManager-children" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.4 }}>
			{children}
		</motion.div>
	)
}
