import { useEffect, useState } from "react";

import { useAppSelector } from "store";

import { StockTableRowContent } from "./StocksTable";
import { CreatableManageStockHistoryDrawer } from "./module/ManageStockHistoryDrawer";
import { useMediaQuery, useTheme } from "@mui/material";
import { IBackendUserLevel } from "interfaces";

const useModule = () => {
	/** @Globals */
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	/** @Selectors */
	const isInternetConnected = useAppSelector(state => state.session.isWebSocketConnected)
	const stateStorageStocks = useAppSelector(state => state.storage.stockPriceHistory)
	const stateStocks = useAppSelector(state => state.vault.stocks)
	const stateStockStatistics = useAppSelector(state => state.statistics.stocks)
	const isPremium = useAppSelector(state => state.session.level !== IBackendUserLevel.ULRegular)

	/** @States */
	const [rows, setrows] = useState<StockTableRowContent[]>([])

	/** @Functions */
	const handleManageStock = async (stockTicker: string) => {
		CreatableManageStockHistoryDrawer({
			stockTicker,
		})

		return
	}

	const isRowDisabled = (ticker: string) => {
		if (isPremium) {
			return false
		}

		return !stateStockStatistics.nonPremiumStocks?.[ticker]?.enabled
	}

	/** @Effects */
	useEffect(() => {

		const newRows = Object.values(stateStocks).map((stock) => {

			return {
				id: stock.ticker,
				ticker: stock.ticker,
				shares: stateStockStatistics.sharesByStock?.[stock.ticker] || 0,
				currentValue: stateStockStatistics.byStock?.[stock.ticker]?.all.currentValue || 0,
				lastPrice: stateStorageStocks?.[stock.ticker]?.records?.[stateStorageStocks?.[stock.ticker]?.mostRecentRecordDate || '']?.close || 0.0,
				todaysGain: (stateStockStatistics.byStock?.[stock.ticker]?.daily.amount || 0),
				todaysGainPercentage: (stateStockStatistics.byStock?.[stock.ticker]?.daily.percentage || 0) / 100,
				totalDividendIncome: (stateStockStatistics.dividendIncomeByStock?.[stock.ticker] || 0.0),
				totalGain: (stateStockStatistics.byStock?.[stock.ticker]?.all.amount || 0),
				totalGainPercentage: (stateStockStatistics.byStock?.[stock.ticker]?.all.percentage || 0) / 100,
				totalReturn: (stateStockStatistics.dividendIncomeByStock?.[stock.ticker] || 0) + (stateStockStatistics.totalReturnPerStock?.[stock.ticker] || 0)
			} as StockTableRowContent

		})

		setrows(newRows)

	}, [stateStocks, stateStockStatistics])


	/** @Constants */

	return {
		flags: {
			isInternetConnected,
		},
		local: {
			rows,
			isMobile
		},
		methods: {
			isRowDisabled,
			handleManageStock,
		}
	}
}

export default useModule;