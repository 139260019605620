/* eslint-disable @typescript-eslint/no-explicit-any */
import numeral from 'numeral';
import React, { CSSProperties, PropsWithChildren } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { NumericFormat } from 'react-number-format';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Box, Button, Divider, Drawer, Grid, IconButton, Input, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface ContainerProps {
	title: string
	subtitle?: string
	isOpen: boolean
	defaultContainer?: boolean
	defaultActions?: boolean
	onOkLabel?: string
	onOkDisabled?: boolean
	onCancelLabel?: string
	onCancelDisabled?: boolean
	onOk?: () => void,
	onCancel?: () => void
	secondaryHeaderButtonIcon?: React.ReactNode
	secondaryHeaderButtonAction?: () => void
	thirdHeaderButtonIcon?: React.ReactNode
	thirdHeaderButtonAction?: () => void
}

export const Container: React.FC<PropsWithChildren & ContainerProps> = (props) => {
	const { children } = props
	const intl = useIntl()

	return (
		<Drawer open={props.isOpen} PaperProps={{ sx: { bgcolor: 'background.lighter', minWidth: "30%", maxWidth: { lg: "65%", md: "90%", sm: "80%", xs: "100%" } } }} onClose={props?.onCancel}>
			<Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} sx={{ py: 4, px: 4, }}>
				{/** Title */}
				<Grid item xs={10}>
					<Typography variant="h1" fontWeight="medium">
						{props.title}
					</Typography>
					{props.subtitle && (
						<Typography variant="h4" sx={{ color: 'text.secondary' }}>
							{props.subtitle}
						</Typography>
					)}
				</Grid>
				<Grid item xs={2} sx={{ textAlign: 'right', justifyContent: 'flex-end', display: 'flex', flexDirection: { xs: "column-reverse", sm: "column-reverse", md: "row", lg: "row" } }}>
					{props.thirdHeaderButtonIcon && (
						<IconButton onClick={props.thirdHeaderButtonAction}>
							{props.thirdHeaderButtonIcon}
						</IconButton>
					)}
					{props.secondaryHeaderButtonIcon && (
						<IconButton onClick={props.secondaryHeaderButtonAction}>
							{props.secondaryHeaderButtonIcon}
						</IconButton>
					)}
					<IconButton onClick={props.onCancel}>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				{/** Content */}
				<Grid item xs={12} sx={{ flexGrow: 1 }}>
					{props.defaultContainer ? (
						<Box sx={{ gap: 4, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
							{children}
						</Box>
					) : children}
				</Grid>
				{/** Actions */}
				{props.defaultActions && (
					<Grid item xs={12} sx={{ flexGrow: 0 }}>
						<Grid container justifyContent="stretch" alignItems="center" spacing={2}>
							<Grid item xs={12} sm={6}>
								<Button fullWidth variant='text' onClick={props?.onCancel} disabled={props.onCancelDisabled}>{props.onCancelLabel || intl.formatMessage({ defaultMessage: 'Cancel', id: 'templateDrawer.btn.label.cancel' })}</Button>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Button fullWidth variant="contained" onClick={props?.onOk} disabled={props.onOkDisabled}>{props.onOkLabel || intl.formatMessage({ defaultMessage: 'Confirm', id: 'templateDrawer.btn.label.ok' })}</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
		</Drawer>
	)

}

interface CustomInputLabelProps {
	label: string
	required?: boolean
	placeholder?: string
	disabled?: boolean
	error?: string
}

export const CustomInputLabel: React.FC<CustomInputLabelProps & PropsWithChildren> = (props) => {
	const { children } = props

	return (
		<Box>
			<Typography variant="h4" fontWeight="medium">
				{props.label}
				{props.required && (<Typography component="span" sx={{ color: 'error.main', fontWeight: 'inherit', fontSize: 'inherit' }}>{" *"}</Typography>)}
			</Typography>
			{children}
			{props.error && (
				<Typography variant="body1" fontWeight="medium" sx={{ color: 'error.main', mt: 0.5, ml: 1 }}>
					{props.error}
				</Typography>
			)}
		</Box>
	)

}

interface NumberInputProps {
	value: number | null,
	onChange: (n: number | undefined) => void,
	adornment?: string
	min?: number
	max?: number
	allowNegative?: boolean
	disableDecimalScale?: boolean
}

export const NumberInput: React.FC<NumberInputProps & CustomInputLabelProps> = (props) => {
	return (
		<CustomInputLabel {...props}>
			<NumericFormat
				customInput={Input}
				size='small'
				fullWidth
				{...(props.disableDecimalScale ? {} : { decimalScale: 2 })}
				isAllowed={(values) => {
					const { floatValue } = values;

					if (!floatValue) return true;

					if (props.max) {
						if (floatValue > props.max) {
							return false
						}
					}

					if (props.min) {
						if (floatValue < props.min) {
							return false
						}
					}

					return true
				}}
				thousandSeparator=","
				min={props.min}
				max={props.max}
				allowNegative={props.allowNegative}
				startAdornment={<InputAdornment position="start">{props.adornment === undefined ? '$' : props.adornment}</InputAdornment>}
				placeholder={props.placeholder}
				onKeyDown={(event) => {
					if (event?.code === 'ArrowDown' || event?.code === 'ArrowUp' || event?.key === '+') {
						event.preventDefault();
					}
				}}
				value={props.value}
				onChange={(e) => props?.onChange?.(e.target.value ? numeral(e.target.value).value() || undefined : undefined)}
				disabled={props.disabled}
			/>
		</CustomInputLabel>
	)
}

interface TextInputProps {
	value: string | null,
	onChange: (n: string) => void
}

export const TextInput: React.FC<TextInputProps & CustomInputLabelProps> = (props) => {
	return (
		<CustomInputLabel  {...props}>
			<Input
				id="text"
				size='small'
				type="text"
				fullWidth
				value={props.value || ""}
				placeholder={props.placeholder}
				onChange={(e) => props?.onChange?.(e.target.value)}
				disabled={props.disabled}
			/>
		</CustomInputLabel>
	)
}

interface SelectInputProps {
	value: any | null,
	defaultValue?: any | null,
	onChange: (n: any) => void,
	options?: { value: any, label: string }[]
	customOptions?: React.ReactNode[]
	multiple?: boolean
}

export const SelectInput: React.FC<SelectInputProps & CustomInputLabelProps> = (props) => {
	return (
		<CustomInputLabel {...props}>
			<Select
				variant='standard'
				size='small'
				fullWidth
				defaultValue={props.defaultValue}
				value={props.value || undefined}
				placeholder={props.placeholder}
				onChange={(e) => props?.onChange?.(e.target.value)}
				disabled={props.disabled}
			>
				{props?.options?.map(x => (
					<MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>
				))}
				{props.customOptions?.map(x => x)}
			</Select>
		</CustomInputLabel>
	)
}


interface DateInputProps {
	value: Date | null,
	onChange: (n: Date | null) => void
	disableFuture?: boolean
	shouldDisableDate?: (date: Date) => boolean
}

export const DateInput: React.FC<DateInputProps & CustomInputLabelProps> = (props) => {
	return (
		<CustomInputLabel {...props}>
			<MobileDatePicker
				openTo="day"
				inputFormat='dd-MM-yyyy'
				views={['day', 'month', 'year']}
				value={props.value}
				disableFuture={props.disableFuture}
				shouldDisableDate={props.shouldDisableDate}
				onChange={(e) => props?.onChange?.(e)}
				renderInput={({ inputProps }) => {
					return (<Input inputProps={inputProps} fullWidth placeholder={props.placeholder} />)
				}}
				disabled={props.disabled}
			/>
		</CustomInputLabel>
	)
}

interface ColorInputProps {
	value: string | null,
	onChange: (n: string | null) => void,
	style?: CSSProperties
}

export const ColorInput: React.FC<ColorInputProps & CustomInputLabelProps> = ({ style, ...props }) => {
	return (
		<CustomInputLabel {...props}>
			<input style={{ width: '100%', border: 'none', background: 'none', appearance: 'none', cursor: 'pointer', borderRadius: "15px", ...(style || {}) }} type="color" value={props.value || ''} onChange={(e) => props?.onChange?.(e.target.value)} />
		</CustomInputLabel>
	)
}