import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Button, CircularProgress, Typography } from '@mui/material'

import { useAppSelector } from 'store'

import { ReactComponent as Logo1000 } from 'static/logo/Logo1000.svg'
import { ISessionWSStatus } from 'store/slices'

export const ConnectionDisplay = () => {
	const intl = useIntl()
	const selectedTheme = useAppSelector(state => state.siteConfiguration.theme)
	const isLoading = useAppSelector(state => state.session.webSocketConnectionStatus === ISessionWSStatus.LOADING)
	const isThereWSError = useAppSelector(state => state.flags.app['internal:wsError'])
	const isMaxTriesReached = useAppSelector(state => state.flags.app['internal:wsMaxLimitReached'])

	if (!isThereWSError) return null

	return (
		<Box sx={{
			position: 'absolute',
			width: "100vw",
			height: "100vh",
			backgroundColor: selectedTheme === 'light' ? 'white' : 'black',
			zIndex: 2000,
			display: 'grid',
			pl: "20px",
			pr: "20px",
			placeContent: 'center',
			overflow: 'hidden',
		}}
		>
			<Box
				sx={{
					margin: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '20px',
					flexDirection: 'column',
				}}
			>
				<Logo1000
					width="100px"
					height="100px"
				/>
				<Box sx={{ textAlign: 'center' }}>
					<Typography variant='h1' fontWeight="medium" color="primary">
						{intl.formatMessage({ id: 'connectionDisplay.title', defaultMessage: `Oh no!` })}
					</Typography>
					<Typography variant="h2" fontWeight={300} color="text.primary">
						{intl.formatMessage({ id: 'connectionDisplay.subtitle', defaultMessage: `You've lost connection to the platform.` })}
					</Typography>
				</Box>
				{!isMaxTriesReached && (
					<Typography color="text.primary">
						{isLoading && (<CircularProgress size={20} sx={{ marginRight: "10px" }} />)}
						{!isLoading && intl.formatMessage({ id: 'connectionDisplay.attemptToReconnect', defaultMessage: `Attempting to reconnect in 5 seconds` })}
						{isLoading && intl.formatMessage({ id: 'connectionDisplay.reconnecting', defaultMessage: `Attempting to reconnect...` })}
					</Typography>
				)}
				<Button variant="outlined" color="primary" onClick={() => window.location.reload()}>
					{intl.formatMessage({ id: 'connectionDisplay.reloadPage', defaultMessage: `Reload page` })}
				</Button>
			</Box>
		</Box>
	)
}

