import { Box, Skeleton, SxProps, Theme, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { useAppSelector } from 'store'

export const WidgetContainerStyle: SxProps<Theme> = {
	bgcolor: 'background.paper',
	minWidth: '10.6667rem',
	minHeight: '100%',
	color: 'text.primary',
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	padding: "15px",
	gap: "5px",
	borderRadius: "5px",
	border: "1px solid hsl(240, 3.7%, 15.9%)",
	flex: "none",
	alignSelf: "stretch",
	flexGrow: 0
}

export interface ITemplateWidgetProps {
	loading?: boolean
	moduleName?: string
	showModuleName?: boolean
	sx?: SxProps<Theme>
}

export const TemplateWidget: React.FC<PropsWithChildren & ITemplateWidgetProps> = (props) => {
	const selectedTheme = useAppSelector(state => state.siteConfiguration.theme)

	if (props.loading) {
		<Box sx={WidgetContainerStyle}>
			<Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
		</Box>
	}

	return (
		<Box sx={{ ...WidgetContainerStyle, ...props.sx }}>
			{props.showModuleName && (
				<>
					<Typography variant='h4' fontWeight="lighter" sx={{ color: selectedTheme === 'light' ? 'secondary.main' : 'primary.main' }}>
						{props.moduleName || ''}
					</Typography>
				</>
			)}
			{props.children}
		</Box >
	)
}
